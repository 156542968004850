import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_SERVICE_PATH}/language-api`,
  headers: {
    "x-auth-token": localStorage.getItem("sgr_user_token"),
  },
});

export const getLanguages = (payload) => api.get("/get-languages", { params: payload });
export const updateLanguage = (langCode, payload) => api.post(`/update-language/${langCode}`, payload);

export default {
  getLanguages,
  updateLanguage,
};
